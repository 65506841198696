import { For, Match, Switch, createEffect, splitProps } from "solid-js";
import { AMaterialLessonItem, ShowMaterialLessonItemViewProps } from "../../../models";
import { state } from ":mods";
import { elementStyleToHTMLStyle } from "../../../methods";

export function Item(props: ShowMaterialLessonItemViewProps) {
  const [local, others] = splitProps(props, ["item", "style"]);
  const $item = state.createMemo(() => {
    return local.item;
  });
  return (
    <Switch fallback={<span>Unknown content item type, please contact support!</span>}>
      <Match when={$item().type === "image"}>
        <GetImage {...($item() as any)} />
      </Match>
      <Match when={$item().type === "video"}>
        <GetVideo {...($item() as any)} />
      </Match>
      <Match when={$item().type === "text"}>
        <GetText {...($item() as any)} />
      </Match>
    </Switch>
    // <div {...others} style={local.item.style as any}>
    // </div>
  );
}

function GetImage(props: AMaterialLessonItem<"image">) {
  let ref: HTMLImageElement = undefined;
  createEffect(() => {
    if (ref) {
      ref.setAttribute("style", elementStyleToHTMLStyle(props.style));
    }
  });
  return <img src={props.value} ref={ref} />;
}
function GetVideo(props: AMaterialLessonItem<"video">) {
  let ref: HTMLIFrameElement = undefined;
  createEffect(() => {
    if (ref) {
      ref.setAttribute("style", elementStyleToHTMLStyle(props.style));
    }
  });
  return (
    <iframe
      ref={ref}
      src={props.value}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
}
function GetText(props: AMaterialLessonItem<"text">) {
  // console.log("updated text");
  let ref: HTMLParagraphElement = undefined;
  createEffect(() => {
    if (ref) {
      ref.setAttribute("style", elementStyleToHTMLStyle(props.style));
    }
  });
  return <p ref={ref}>{props.value}</p>;
}
